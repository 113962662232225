import React, { Fragment } from 'react'
import propTypes from 'prop-types'
import VendorDropdown from './VendorDropdown'

const SingleVendorSelector = ({ api, name, onChange, ...props }) => {
  /**
   * Trigger on change on parent.
   */
  const handleOnChange = (vendors) => {
    onChange({
      target: {
        name, value: vendors
      }
    })
  }

  return (
        <Fragment>
            <div>
                <VendorDropdown
                 api={api}
                 displaySelected={true}
                 vendor={props.vendor}
                 onSelect={handleOnChange}
                />
            </div>
        </Fragment>
  )
}

// define types for props
SingleVendorSelector.propTypes = {
  api: propTypes.object,
  vendor: propTypes.object,
  name: propTypes.string,
  onChange: propTypes.func
}

export default SingleVendorSelector
