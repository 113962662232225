import React from 'react'
import SearchDropdown from './SearchDropdown'
import PropTypes from 'prop-types'

const VendorDropdown = ({ api, vendor, onSelect, displaySelected }) => {
  // search vendor
  const vendorSearch = (name) => {
    return api.get('vendors', { search: name }).then(({ data }) => {
      return data.vendors
    })
  }

  return (
    <SearchDropdown
      name="vendor"
      value={vendor}
      onSelect={
        (e) => {
          onSelect(e.target.value)
        }
      }
      displayOnSelected={displaySelected ? (vendor) => (vendor.name) : (vendor) => {}}
      displayOnList={(vendor) => (vendor.name)}
      itemToId={(vendor) => (vendor.id)}
      searchMethod={vendorSearch}
      onInputChange={(vendor) => {}}
    />
  )
}

// define types for props
VendorDropdown.propTypes = {
  onSelect: PropTypes.func,
  vendor: PropTypes.object,
  api: PropTypes.object,
  displaySelected: PropTypes.bool
}

export default VendorDropdown
