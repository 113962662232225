import { useState, useEffect, useCallback } from "react";

function useDataEntity(api, id, endpoint, type) {

  const [loading, setLoading] = useState(true);
  const [results, setResults] = useState({});
  const [error, setError] = useState("");
  
  useEffect(() => {

    async function fetchData() {
      try {

        const json = await api.get(`${endpoint}?id=${id}`);
        var data = json.data[type];
        setResults(data);
        setLoading(false);
        
      } catch (error) {
        console.log(error);
        setLoading(false);
        setError(error.message);
      }

      //setLoading(false);
    }


    fetchData();
  }, [id]);

  return {
    error,
    loading,
    results,
  };
}

export default useDataEntity;