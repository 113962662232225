import React, { useState, Fragment } from 'react'
import { useHistory } from 'react-router-dom'
import Header from '../Header'
import { Label, Input, Textarea, Checkbox } from '@zendeskgarden/react-forms'

import AttachmentsEditor from './AttachmentsEditor'
import CustomFieldsEditor from './CustomFieldsEditor'
import ContactsEditor from './ContactsEditor'
import LocationsEditor from './LocationsEditor'
import SingleVendorSelector from './SingleVendorSelector'
import ProductsSelector from './ProductsSelector'
import propTypes from 'prop-types'
import { Button } from '@zendeskgarden/react-buttons'
import { EditorField } from '../StyledDefinitions'
import { Inline } from '@zendeskgarden/react-loaders'

function EditVendorForm ({ onSubmit, api, ...props }) {
  const [vendor, setVendor] = useState(props.vendor)
  const [saving, setSaving] = useState(false)
  const history = useHistory()

  /**
   * Update vendor field
   */
  const onInputChange = (e) => {
    setVendor({ ...vendor, [e.target.name]: e.target.value })
  }

  // disable button
  const saveDisabled = !vendor.name || vendor.locations?.find((l) => (!l.address_line_1)) ||
    vendor.contacts?.find((l) => (!l.name))

  // return product view
  return (
    <Fragment>
      <form>
        <section>
            <a onClick={() => { history.goBack() }}>Back</a>
            <Header><h1 className="vendor-name">{props.vendor.name}</h1></Header>
        </section>

        <EditorField>
            <Label>Name*</Label>
            <Input name="name" isCompact value={vendor.name} onChange={onInputChange}/>
        </EditorField>

        <EditorField>
            <Label>Type</Label>
            <Input name="type" isCompact value={vendor.type} onChange={onInputChange}/>
        </EditorField>

        <EditorField>
            <Label>Number</Label>
            <Input name="number" isCompact value={vendor.number} onChange={onInputChange}/>
        </EditorField>

        <EditorField>
            <Label>PO</Label>
            <Input name="po" isCompact value={vendor.po} onChange={onInputChange}/>
        </EditorField>

        <EditorField>
            <Label>Phone Number</Label>
            <Input name="phone_number" isCompact value={vendor.phone_number} onChange={onInputChange}/>
        </EditorField>

        <EditorField>
            <Label>Buyer</Label>
            <Input name="buyer" isCompact value={vendor.buyer} onChange={onInputChange}/>
        </EditorField>

        <EditorField>
            <Label>Purchased From</Label>
            <Input name="purchased_from" isCompact value={vendor.purchased_from} onChange={onInputChange}/>
        </EditorField>

        <EditorField>
            <Label>Customer Service General</Label>
            <Input name="customer_service_general" isCompact value={vendor.customer_service_general} onChange={onInputChange}/>
        </EditorField>

        <EditorField>
            <Label>Inside Contact Name</Label>
            <Input name="inside_contact_name" isCompact value={vendor.inside_contact_name} onChange={onInputChange}/>
        </EditorField>

        <EditorField>
            <Label>Inside Contact Email</Label>
            <Input name="inside_contact_email" isCompact value={vendor.inside_contact_email} onChange={onInputChange}/>
        </EditorField>

        <EditorField>
            <Label>Inside Contact Phone (direct)</Label>
            <Input name="inside_contact_phone_direct" isCompact value={vendor.inside_contact_phone_direct} onChange={onInputChange}/>
        </EditorField>

        <EditorField>
            <Label>Field Sales Rep Name</Label>
            <Input name="field_sales_rep_name" isCompact value={vendor.field_sales_rep_name} onChange={onInputChange}/>
        </EditorField>

        <EditorField>
            <Label>Field Sales Rep Cell (direct)</Label>
            <Input name="field_sales_rep_cell_direct" isCompact value={vendor.field_sales_rep_cell_direct} onChange={onInputChange}/>
        </EditorField>

        <EditorField>
            <Label>Field Sales Rep Email</Label>
            <Input name="field_sales_rep_email" isCompact value={vendor.field_sales_rep_email} onChange={onInputChange}/>
        </EditorField>

        <EditorField>
            <Label>Product Engineering Support Name</Label>
            <Input name="product_engineering_supprt_name" isCompact value={vendor.product_engineering_supprt_name} onChange={onInputChange}/>
        </EditorField>

        <EditorField>
            <Label>Product Engineering Support Direct</Label>
            <Input name="product_engineering_supprt_direct" isCompact value={vendor.product_engineering_supprt_direct} onChange={onInputChange}/>
        </EditorField>

        <EditorField>
            <Label>Product Engineering Support Email</Label>
            <Input name="product_engineering_supprt_email" isCompact value={vendor.product_engineering_supprt_email} onChange={onInputChange}/>
        </EditorField>

        <EditorField>
            <Label>BSI Account Number</Label>
            <Input name="bsi_account_number" isCompact value={vendor.bsi_account_number} onChange={onInputChange}/>
        </EditorField>

        <EditorField>
            <Checkbox checked={vendor.prepaid_freight} isCompact onChange={(e) => {
              onInputChange({ target: { name: 'prepaid_freight', value: !vendor.prepaid_freight } })
            }}>
              <Label style={{ marginBottom: '0px' }}>Prepaid Freight</Label>
            </Checkbox>
        </EditorField>

        <EditorField>
            <Checkbox checked={vendor.surplus} isCompact onChange={(e) => {
              onInputChange({ target: { name: 'surplus', value: !vendor.surplus } })
            }}>
              <Label style={{ marginBottom: '0px' }}>Surplus</Label>
            </Checkbox>
        </EditorField>

        <EditorField>
            <Label>Vendor Website</Label>
            <Input name="website" isCompact value={vendor.website} onChange={onInputChange}/>
        </EditorField>

        <EditorField>
            <Label>Parent Vendor</Label>
            <SingleVendorSelector name="parent" vendor={vendor.parent} api={api} onChange={onInputChange}/>
        </EditorField>

        <EditorField>
            <Label>Additional Info</Label>
            <Textarea name="additional_info" isCompact value={vendor.additional_info} onChange={onInputChange}/>
        </EditorField>

        <EditorField>
            <Label>Other Contacts</Label>
            <ContactsEditor name="contacts" contacts={vendor.contacts} onChange={onInputChange}/>
        </EditorField>

        <EditorField>
            <Label>Shopping Locations</Label>
            <LocationsEditor name="locations" locations={vendor.locations} onChange={onInputChange}/>
        </EditorField>

        <EditorField>
            <Label>Attachments</Label>
            <AttachmentsEditor name="attachments" attachments={vendor.attachments} onChange={onInputChange}/>
        </EditorField>

        <EditorField>
            <Label>Custom Fields</Label>
            <CustomFieldsEditor name="custom_fields" customFields={vendor.custom_fields} onChange={onInputChange}/>
        </EditorField>

        <EditorField>
            <Label>Products</Label>
            <ProductsSelector name="products" products={vendor.products} api={api} onChange={onInputChange}/>
        </EditorField>

        <Button isPrimary disabled={saveDisabled} onClick={() => {
          setSaving(true)
          onSubmit(vendor).finally(() => setSaving(false))
        }}>
            {saving ? <Inline/> : <span>Save</span>}
        </Button>

      </form>
    </Fragment>
  )
}

// define types for props
EditVendorForm.propTypes = {
  vendor: propTypes.object,
  onSubmit: propTypes.func,
  api: propTypes.object
}

export default EditVendorForm
