import React, { useState, useEffect, Fragment } from 'react'
import { Skeleton } from '@zendeskgarden/react-loaders'
import propTypes from 'prop-types'
import EditVendorForm from './Editors/EditVendorForm'
import { useHistory } from 'react-router-dom'

function VendorEdit (props) {
  const [vendor, setVendor] = useState(undefined)
  const [error, setError] = useState(undefined)
  console.log(vendor)
  useEffect(() => {
    // build a new one if the id is new
    if (props.match.params.id === 'new') {
      setVendor({
        additional_info: '',
        attachments: [],
        bsi_account_number: '',
        buyer: '',
        contacts: [],
        custom_fields: [],
        customer_service_general: '',
        field_sales_rep_cell_direct: '',
        field_sales_rep_email: '',
        field_sales_rep_name: '',
        inside_contact_email: '',
        inside_contact_name: '',
        inside_contact_phone_direct: '',
        locations: [],
        min_buy: '',
        name: '',
        number: '',
        parent_vendor_id: null,
        phone_number: '',
        po: '',
        prepaid_freight: false,
        surplus: false,
        product_engineering_supprt_direct: '',
        product_engineering_supprt_email: '',
        product_engineering_supprt_name: '',
        products: [],
        purchased_from: '',
        type: '',
        website: ''
      })
    } else {
      // load vendor
      props.api.get(`vendors?id=${props.match.params.id}`).then(({ data }) => { setVendor(data.vendor) })
    }
  }, [props.match.params.id])

  // get history
  const history = useHistory()
  // if loading, display skeletons
  if (vendor === undefined) {
    return (<Fragment><Skeleton></Skeleton><Skeleton></Skeleton><Skeleton></Skeleton><Skeleton></Skeleton></Fragment>)
  } else {
    return (
    <>
      <EditVendorForm key={props.match.params.id} vendor={vendor} api={props.api} onSubmit={async (vendor) => {
        const payload = {
          vendor: {
            id: vendor.id,
            additional_info: vendor.additional_info,
            attachments: vendor.attachments?.map((a) => (
              {
                id: a.id,
                attachment_url: a.attachment_url,
                title: a.title,
                _destroy: a._destroy
              }
            )),
            bsi_account_number: vendor.bsi_account_number,
            buyer: vendor.buyer,
            contacts: vendor.contacts?.map((c, index) => (
              {
                id: c.id,
                email: c.email,
                name: c.name,
                order: index,
                phone: c.phone,
                position: c.position,
                _destroy: c._destroy
              }
            )),
            custom_fields: vendor.custom_fields?.map((c) => ({
              key: c.key,
              value: c.value
            })),
            customer_service_general: vendor.customer_service_general,
            field_sales_rep_cell_direct: vendor.field_sales_rep_cell_direct,
            field_sales_rep_email: vendor.field_sales_rep_email,
            field_sales_rep_name: vendor.field_sales_rep_name,
            inside_contact_email: vendor.inside_contact_email,
            inside_contact_name: vendor.inside_contact_name,
            inside_contact_phone_direct: vendor.inside_contact_phone_direct,
            locations: vendor.locations?.map((l, index) => ({
              id: l.id,
              address_line_1: l.address_line_1,
              address_line_2: l.address_line_2,
              city: l.city,
              country: l.country,
              order: index,
              state: l.state,
              zip_code: l.zip_code,
              _destroy: l._destroy
            })),
            min_buy: vendor.min_buy,
            name: vendor.name,
            number: vendor.number,
            parent: vendor.parent ? { id: vendor.parent.id } : null,
            phone_number: vendor.phone_number,
            po: vendor.po,
            prepaid_freight: vendor.prepaid_freight,
            surplus: vendor.surplus,
            product_engineering_supprt_direct: vendor.product_engineering_supprt_direct,
            product_engineering_supprt_email: vendor.product_engineering_supprt_email,
            product_engineering_supprt_name: vendor.product_engineering_supprt_name,
            products: vendor.products?.map((product) => ({ id: product.id })),
            purchased_from: vendor.purchased_from,
            type: vendor.type,
            website: vendor.website
          }
        }
        // send to api
        setError(undefined)
        return props.api.create('vendors', payload).then(({ data }) => {
          // go to view page
          history.push('/vendors/' + data.vendor.id)
        }).catch((error) => {
          setError(error)
          console.log('Error saving vendor', error)
        })
      }}/>
      { error && <>
        <div style={{ color: 'red', marginTop: '20px', marginBottom: '10px;' }}>
          There was an error saving the vendor. Please review your data and try again.
        </div>
      </>}
    </>
    )
  }
}

// define types for props
VendorEdit.propTypes = {
  props: propTypes.object
}

export default VendorEdit
