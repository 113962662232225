import React from 'react'
import SearchDropdown from './SearchDropdown'
import PropTypes from 'prop-types'

const ProductDropdown = ({ api, product, onSelect, displaySelected }) => {
  // search product
  const productSearch = (name) => {
    return api.get('products', { search: name }).then(({ data }) => {
      return data.products
    })
  }

  return (
    <SearchDropdown
      name="product"
      value={product}
      onSelect={
        (e) => {
          onSelect(e.target.value)
        }
      }
      displayOnSelected={displaySelected ? (product) => (product.name) : (product) => {}}
      displayOnList={(product) => (product.name)}
      itemToId={(product) => (product.id)}
      searchMethod={productSearch}
      onInputChange={(product) => {}}
    />
  )
}

// define types for props
ProductDropdown.propTypes = {
  onSelect: PropTypes.func,
  product: PropTypes.object,
  api: PropTypes.object,
  displaySelected: PropTypes.bool
}

export default ProductDropdown
