import React, { useState, Fragment } from 'react'
import { useHistory } from 'react-router-dom'
import Header from '../Header'
import { Label, Input, Textarea } from '@zendeskgarden/react-forms'
import AttachmentsEditor from './AttachmentsEditor'
import CustomFieldsEditor from './CustomFieldsEditor'
import VendorsSelector from './VendorsSelector'
import propTypes from 'prop-types'
import { Button } from '@zendeskgarden/react-buttons'
import { EditorField } from '../StyledDefinitions'
import { Inline } from '@zendeskgarden/react-loaders'

function EditProductForm ({ onSubmit, api, ...props }) {
  const [product, setProduct] = useState(props.product)
  const [saving, setSaving] = useState(false)
  const history = useHistory()

  /**
   * Update product field
   */

  const onInputChange = (e) => {
    setProduct({ ...product, [e.target.name]: e.target.value })
  }

  // disable button
  const saveDisabled = !product.name

  // return product view
  return (
    <Fragment>
      <form>
        <section>
            <a onClick={() => { history.goBack() }}>Back</a>
            <Header><h1 className="product-name">{props.product.name}</h1></Header>
        </section>

        <EditorField>
            <Label>Name*</Label>
            <Input name="name" isCompact value={product.name} onChange={onInputChange}/>
        </EditorField>

        <EditorField>
            <Label>Code</Label>
            <Input name="code" isCompact value={product.code} onChange={onInputChange}/>
        </EditorField>

        <EditorField>
            <Label>Description</Label>
            <Textarea name="description" isCompact value={product.description} onChange={onInputChange}/>
        </EditorField>

        <EditorField>
            <Label>Attachments</Label>
            <AttachmentsEditor name="attachments" attachments={product.attachments} onChange={onInputChange}/>
        </EditorField>

        <EditorField>
            <Label>Custom Fields</Label>
            <CustomFieldsEditor name="custom_fields" customFields={product.custom_fields} onChange={onInputChange}/>
        </EditorField>

        <EditorField>
            <Label>Vendors</Label>
            <VendorsSelector name="vendors" vendors={product.vendors} api={api} onChange={onInputChange}/>
        </EditorField>

        <Button isPrimary disabled={saveDisabled} onClick={() => {
          setSaving(true)
          onSubmit(product).finally(() => setSaving(false))
        }}>
            {saving ? <Inline/> : <span>Save</span>}
        </Button>
      </form>
    </Fragment>
  )
}

// define types for props
EditProductForm.propTypes = {
  product: propTypes.object,
  onSubmit: propTypes.func,
  api: propTypes.object
}

export default EditProductForm
