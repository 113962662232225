import React, { useEffect, useState, Fragment } from 'react'
import { useHistory, Link } from 'react-router-dom'
import { Skeleton } from '@zendeskgarden/react-loaders'
import Header from './Header'
import { CenteredTable, Row, Cell } from './StaticTable'
import propTypes from 'prop-types'

function ProductView (props) {
  const [product, setProduct] = useState(undefined)

  useEffect(() => {
    // build a new one if the id is new
    if (props.match.params.id === 'new') {
      setProduct({
        id: null,
        attachments: [],
        code: '',
        custom_fields: null,
        description: '',
        name: '',
        vendors: []
      })
    } else {
      // load product
      props.api.get(`products?id=${props.match.params.id}`).then(({ data }) => { setProduct(data.product) })
    }
  }, [])

  const history = useHistory()
  // if loading, display skeletons
  if (product === undefined) {
    return (<Fragment><Skeleton></Skeleton><Skeleton></Skeleton><Skeleton></Skeleton><Skeleton></Skeleton></Fragment>)
  } else {
    // get vendors and attachments
    const vendors = (product.vendors || []).map((v) => (<Vendor key={v.id} vendor={v}></Vendor>))
    const attachments = (product.attachments || []).map((a) => (<Attachment key={a.id} attachment={a}></Attachment>))
    const customFields = product.custom_fields

    // return product view
    return (
            <Fragment>
                <a onClick={() => { history.goBack() }}>Back</a>
                <Header>
                    <h1 className="product-name">
                        {product.name}
                        {props.account && props.account.roles.includes('Admin') && // admin can edit
                            <div style={{ display: 'inline-block', marginLeft: '10px', fontWeight: '100' }}>
                                <Link style={{ }} to={`/products/${props.match.params.id}/edit`}>
                                    <p>Edit</p>
                                </Link>
                            </div>
                        }
                    </h1>
                </Header>
                <CenteredTable className="centered-table">
                    <Row>
                        <Cell>
                            <b>Code</b><br />{product.code}
                        </Cell>
                    </Row>
                    <Row>
                        <Cell style={{ textAlign: 'left', whiteSpace: 'pre-line' }}>
                            <b>Description</b><br />{product.description}
                        </Cell>
                    </Row>
                    {customFields &&
                            <>
                                {customFields.map((cf) => (
                                    <Row key={cf.key}>
                                        <Cell style={{ textAlign: 'left', whiteSpace: 'pre-line' }}><b>{cf.key}</b><br />{cf.value}</Cell>
                                    </Row>
                                ))}
                            </>}
                    {attachments.length > 0 &&
                        <Row>
                             <Cell>
                                <b>Attachments</b>
                                {attachments}
                            </Cell>
                        </Row>
                    }
                    {vendors.length > 0 &&
                        <Row>
                            <Cell>
                                <b>Vendors</b><br />
                                {vendors}
                            </Cell>
                        </Row>
                    }
                </CenteredTable>
            </Fragment >
    )
  }
}

const Vendor = ({ vendor }) => {
  return (
        <Link style={{ }} to={`/vendors/${vendor.id}`}>
            <p className="item-link-p">{vendor.name} - {vendor.number}</p>
        </Link>
  )
}
Vendor.propTypes = {
  vendor: propTypes.object
}

const Attachment = ({ attachment }) => {
  return (
        <a rel="noreferrer" style={{ display: 'block', marginTop: '5px' }} href={attachment.attachment_url} target="_blank">
            { attachment.title || attachment.attachment_url }
        </a>
  )
}
Attachment.propTypes = {
  attachment: propTypes.object
}
export default ProductView
