import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

import Login from './login';

// get account
var account = Login.myMSALObj.getAccount();

if (account == null) {
  Login.signIn();
} else {
  // save roles for easy access
  account.roles = account.idToken.roles
  if(!account.roles || account.roles.length === 0) {
    account.roles = ['User']
  }
}

ReactDOM.render(
  <React.StrictMode>
    <App account={account} />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
