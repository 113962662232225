import styled from 'styled-components'
import { Field } from '@zendeskgarden/react-forms'

export const EditorField = styled(Field)`
    margin-bottom: 15px;

    label {
        margin-bottom: 15px;
    }
`
