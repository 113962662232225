import React, { Fragment } from 'react'
import { Button } from '@zendeskgarden/react-buttons'
import propTypes from 'prop-types'
import FromInput from './FormInput'
import { destroy, update, keys, key } from './utils/collection'

const CustomFieldsEditor = ({ onChange, name, ...props }) => {
  // add keys to custom fields
  const customFields = keys(props.customFields || [])

  /**
   * Trigger on change on parent.
   */
  const handleOnChange = (customFields) => {
    onChange({
      target: {
        name, value: customFields
      }
    })
  }

  /**
   * Update a custom field.
   */
  const onCustomFieldChange = (customField, name, value) => {
    handleOnChange(update({
      ...customField,
      [name]: value
    }, customFields))
  }

  return (
        <Fragment>
            {customFields.map((customField) => (
                <div key={customField._key} style={{ marginTop: '0px', marginBottom: '10px' }}>
                  <div>
                    <FromInput
                        placeholder='Title*'
                        isCompact
                        onChange={(e) => onCustomFieldChange(customField, 'key', e.target.value)}
                        value={customField.key}
                    />
                    <FromInput
                        placeholder='Value*'
                        isCompact
                        onChange={(e) => onCustomFieldChange(customField, 'value', e.target.value)}
                        value={customField.value}
                    />
                  </div>
                  <span style={{ color: 'red', cursor: 'pointer' }}
                      onClick={() => {
                        handleOnChange(destroy(customField, customFields))
                      }}> (remove)
                    </span>
                </div>
            ))}
            <div>
                <Button size="small" onClick={() => {
                  handleOnChange([...customFields, {
                    _key: key(),
                    key: '',
                    value: ''
                  }])
                } }>Add</Button>
            </div>
        </Fragment>
  )
}

// define types for props
CustomFieldsEditor.propTypes = {
  onChange: propTypes.func,
  customFields: propTypes.arrayOf(propTypes.object),
  name: propTypes.string
}

export default CustomFieldsEditor
