import React, { Fragment } from 'react'
import propTypes from 'prop-types'
import VendorDropdown from './VendorDropdown'

const VendorsSelector = ({ api, name, onChange, ...props }) => {
  const vendors = props.vendors || []

  /**
   * Trigger on change on parent.
   */
  const handleOnChange = (vendors) => {
    onChange({
      target: {
        name, value: vendors
      }
    })
  }

  return (
        <Fragment>
            {vendors.map((vendor, index) => (
                <div key={index} style={{ marginTop: '0px', marginBottom: '5px' }}>
                  <span>{vendor.name}</span>
                  <span style={{ color: 'red', cursor: 'pointer' }}
                    onClick={() => {
                      handleOnChange([...vendors.slice(0, index), ...vendors.slice(index + 1)])
                    }}> (remove)
                  </span>
                </div>
            ))}
            <div>
                <VendorDropdown
                 api={api}
                 onSelect={(vendor) => { handleOnChange([...vendors, vendor]) }}
                />
            </div>
        </Fragment>
  )
}

// define types for props
VendorsSelector.propTypes = {
  api: propTypes.object,
  name: propTypes.string,
  onChange: propTypes.func,
  vendors: propTypes.arrayOf(propTypes.object)
}

export default VendorsSelector
