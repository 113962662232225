import React, { Fragment } from 'react'
import { Button } from '@zendeskgarden/react-buttons'
import propTypes from 'prop-types'
import FromInput from './FormInput'
import { update, keys, key, destroy } from './utils/collection'

const AttachmentsEditor = ({ onChange, name, ...props }) => {
  // get attachments and assign a key
  const attachments = keys(props.attachments || [])

  /**
   * Trigger on change on parent.
   */
  const handleOnChange = (attachments) => {
    onChange({
      target: {
        name, value: attachments
      }
    })
  }

  /**
   * Update an attachment field.
   */
  const onAttachmentFieldChange = (attachment, name, value) => {
    handleOnChange(update({
      ...attachment,
      [name]: value
    }, attachments))
  }

  return (
        <Fragment>
            {attachments.map((attachment) => (
              !attachment._destroy &&
                <div key={attachment._key} style={{ marginTop: '0px', marginBottom: '10px' }}>
                  <div>
                    <FromInput
                        onChange={(e) => onAttachmentFieldChange(attachment, 'title', e.target.value)}
                        placeholder='Title'
                        isCompact
                        value={attachment.title}
                    />
                    <FromInput
                        onChange={(e) => onAttachmentFieldChange(attachment, 'attachment_url', e.target.value)}
                        placeholder='URL'
                        isCompact
                        value={attachment.attachment_url}
                    />
                  </div>
                  <span style={{ color: 'red', cursor: 'pointer' }}
                      onClick={() => {
                        handleOnChange(destroy(attachment, attachments))
                      }}> (remove)
                  </span>
                </div>
            ))}
            <div>
                <Button size="small" onClick={() => {
                  handleOnChange([...attachments, {
                    title: '',
                    attachment_url: '',
                    _key: key()
                  }])
                } }>Add</Button>
            </div>
        </Fragment>
  )
}

// define types for props
AttachmentsEditor.propTypes = {
  attachments: propTypes.arrayOf(propTypes.object),
  onChange: propTypes.func,
  name: propTypes.string
}

export default AttachmentsEditor
