import React, { useEffect, useState, Fragment } from 'react'
import { Skeleton } from '@zendeskgarden/react-loaders'
import propTypes from 'prop-types'
import EditProductForm from './Editors/EditProductForm'
import { useHistory } from 'react-router-dom'

function ProductEdit (props) {
  const [product, setProduct] = useState(undefined)
  const [error, setError] = useState(undefined)

  useEffect(() => {
    // build a new one if the id is new
    if (props.match.params.id === 'new') {
      setProduct({
        id: null,
        attachments: [],
        code: '',
        custom_fields: null,
        description: '',
        name: '',
        vendors: []
      })
    } else {
      // load product
      props.api.get(`products?id=${props.match.params.id}`).then(({ data }) => { setProduct(data.product) })
    }
  }, [props.match.params.id])

  // get history
  const history = useHistory()
  // if loading, display skeletons
  if (product === undefined) {
    return (<Fragment><Skeleton></Skeleton><Skeleton></Skeleton><Skeleton></Skeleton><Skeleton></Skeleton></Fragment>)
  } else {
    return (
      <>
        <EditProductForm key={props.match.params.id} product={product} api={props.api} onSubmit={async (product) => {
          // build payload
          const payload = {
            product: {
              id: product.id,
              attachments: product.attachments?.map((a) => (
                {
                  id: a.id,
                  attachment_url: a.attachment_url,
                  title: a.title,
                  _destroy: a._destroy
                }
              )),
              code: product.code,
              custom_fields: product.custom_fields?.map((c) => ({
                key: c.key,
                value: c.value
              })),
              description: product.description,
              name: product.name,
              vendors: product.vendors?.map((v) => ({ id: v.id }))
            }
          }
          // send to api
          setError(undefined)
          return props.api.create('products', payload).then(({ data }) => {
            // go to view page
            history.push('/products/' + data.product.id)
          }).catch((error) => {
            setError(error)
            console.log('Error saving product', error)
          })
        }}/>
        { error && <>
          <div style={{ color: 'red', marginTop: '20px', marginBottom: '10px;' }}>
            There was an error saving the product. Please review your data and try again.
          </div>
        </>}
      </>
    )
  }
}

// define types for props
ProductEdit.propTypes = {
  props: propTypes.object
}

export default ProductEdit
