import React from 'react'
import { Field, Input } from '@zendeskgarden/react-forms'
import propTypes from 'prop-types'

const FromInput = ({ ...props }) => {
  return (
    <Field style={{ marginBottom: '5px' }}>
        <Input {...props} />
    </Field>
  )
}

// define types for props
FromInput.propTypes = {
  props: propTypes.object
}

export default FromInput
