import React, { useEffect, useState } from "react";
import { Grid, Row, Col } from '@zendeskgarden/react-grid';
import { Tabs, TabList, Tab, TabPanel } from '@zendeskgarden/react-tabs';
import Table from './Table/Table';
import { useHistory, Link } from 'react-router-dom';
import styles from '../styles.module.css'

function ProductsListView(props) {

    var fields;

    if (props.mobile) {

        fields = [
            {
                key: 'code',
                label: "Name",
                sortable: false,
                fetchable: false,
                renderable: (row) => {
                    return <React.Fragment>{row["name"]}</React.Fragment>
                }
            }
        ];

    } else {

        fields = [
            {
                key: 'code',
                label: "Code Val",
                sortable: false,
                fetchable: false,
                width: 150
            },
            {
                label: "Vendors",
                sortable: false,
                fetchable: false,
                renderable: (row) => {
                    if (row["vendors"] === undefined) row["vendors"] = [];
                    const vendor_list = row["vendors"].map((v, i) => `(${i + 1}) ` + v["name"]).join(", ")
                    return <React.Fragment>{vendor_list}</React.Fragment>
                }
            },
            {
                key: 'name',
                label: "Display Name",
                sortable: true,
            },
            {
                key: 'description',
                label: "Description",
                sortable: true,
            },
        ];
    }



    var options = {
        // page: 1,         // optional
        // per_page: 10,    // optional        
        sort_by: "name",
        order: "desc"
    }

    return (
        <div className={styles.page}>
                <section id="main-products-table">
                    <Table name={`products-list-${props.mobile ? "m" : "d"}`} fields={fields} options={options} endpoint="products" api={props.api}
                        onRowClick={(id) => props.history.push("/products/" + id)}>
                    </Table>
                </section>
        </div>
    );
}

export default ProductsListView;





