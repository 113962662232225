// Config object to be passed to Msal on creation
export default {
    api: {
        "baseUrl": process.env.REACT_APP_baseUrl
    },
    auth: {
        clientId: process.env.REACT_APP_clientId,
        authority: process.env.REACT_APP_authority,
        redirectUri: process.env.REACT_APP_redirectUri,
        scope: process.env.REACT_APP_scope
    },
    cache: {
        cacheLocation: "localStorage",
        storeAuthStateInCookie: true
    }
};
