import axios from 'axios';
import querystring from 'querystring';
import config from '../../config';
import Login from '../../login';

const configFile = {
    "baseUrl": config.api.baseUrl,
}

const api = axios.create({
    baseURL: configFile.baseUrl,
    headers: {
        'Content-Type': 'application/json'
    }
});

export default {
    api,
    async execute(method, resource, data) {

        // get AD token
        const token = await Login.getTokenPopup();

        return api({
            method,
            url: resource,
            data,
            headers: {                
                "Authorization": "Bearer " + token.accessToken  
            }
        })
    },
    get(endpoint, params) {
        var query = querystring.stringify(params);
        return this.execute('get', `/${endpoint}${query !== "" ? "?" + query : ""}`)
    },
    create(endpoint, data) {
        return this.execute('post', `/${endpoint}`, data)
    },
    update(endpoint, data) {
        return this.execute('put', `/${endpoint}`, data)
    },
    delete(endpoint, id) {
        return this.execute('delete', `${endpoint}/${id}`)
    }
}