import React, { useEffect } from 'react';

import logo from './logo.svg';
import './App.css';
import Login from './login';
import { ThemeProvider } from '@zendeskgarden/react-theming';
import './index.css';
import 'components/dist/index.css'
import { ProductsListView } from "components"
import { VendorsListView } from "components"
import { ProductView } from "components"
import { ProductEdit } from "components"
import { VendorView } from "components"
import { VendorEdit } from "components"
import {TabBar} from "components"
import api from './js/utils/Api';
import { Grid, Row as GridRow, Col as GridCol } from '@zendeskgarden/react-grid';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,  
} from "react-router-dom";


function App(props) {
  if (props.account != null) {
    const account = { ...props.account, roles: props.account.roles || ['User'] }

    const logout = () => {
      Login.signOut()
    }

    return (
      <>
        <ThemeProvider>
          <Router>
            <div className="container">          
              <Grid>
                <GridRow>
                  <GridCol md={12}>
                    <TabBar logout={logout} {...props}></TabBar>
                    <Route exact path="/" render={(props) => <VendorsListView {...props} account={account} api={api} />}></Route>
                    <Route exact path="/products/:id" render={(props) => <ProductView {...props} account={account} api={api} />}></Route>
                    <Route exact path="/products/:id/edit" render={(props) => <ProductEdit {...props} account={account} api={api} />}></Route>
                    <Route exact path="/vendors/:id" render={(props) => <VendorView {...props} account={account} api={api} />}></Route>
                    <Route exact path="/vendors/:id/edit" render={(props) => <VendorEdit {...props} account={account} api={api} />}></Route>
                    <Route exact path="/vendors" render={(props) => <VendorsListView {...props} account={account} api={api} />}></Route>
                    <Route exact path="/products" render={(props) => <ProductsListView {...props} account={account} api={api} />}></Route>
                  </GridCol>
                </GridRow>
              </Grid>
            </div>
          </Router>
        </ThemeProvider>
      </>
    )
  } else {
    return (<p>You need to be logged in to see this content. Redirecting to login page.</p>)
  }
}

export default App;

