import React, { Fragment } from 'react'
import { Button } from '@zendeskgarden/react-buttons'
import propTypes from 'prop-types'
import FromInput from './FormInput'
import { update, keys, key, destroy } from './utils/collection'

const ContactsEditor = ({ onChange, name, ...props }) => {
  const contacts = keys(props.contacts || [])

  /**
   * Trigger on change on parent.
   */
  const handleOnChange = (contacts) => {
    onChange({
      target: {
        name, value: contacts
      }
    })
  }

  /**
   * Update a contact field.
   */
  const onContactFieldChange = (contact, name, value) => {
    handleOnChange(update({
      ...contact,
      [name]: value
    }, contacts))
  }

  return (
        <Fragment>
            {contacts.map((contact) => (
                <div key={contact._key} style={{ marginTop: '0px', marginBottom: '10px', display: `${contact._destroy ? 'none' : 'visible'}` }}>
                  <div>
                    <FromInput
                        onChange={(e) => onContactFieldChange(contact, 'name', e.target.value)}
                        placeholder='Name*'
                        isCompact
                        value={contact.name}
                    />
                    <FromInput
                        onChange={(e) => onContactFieldChange(contact, 'position', e.target.value)}
                        placeholder='Position'
                        isCompact
                        value={contact.position}
                    />
                    <FromInput
                        onChange={(e) => onContactFieldChange(contact, 'email', e.target.value)}
                        placeholder='Email Address'
                        isCompact
                        value={contact.email}
                    />
                    <FromInput
                        onChange={(e) => onContactFieldChange(contact, 'phone', e.target.value)}
                        placeholder='Phone Number'
                        isCompact
                        value={contact.phone}
                    />
                  </div>
                  <span style={{ color: 'red', cursor: 'pointer' }}
                      onClick={() => {
                        handleOnChange(destroy(contact, contacts))
                      }}> (remove)
                    </span>
                </div>
            ))}
            <div>
                <Button size="small" onClick={() => {
                  handleOnChange([...contacts, {
                    name: '',
                    position: '',
                    email: '',
                    phone: '',
                    _key: key()
                  }])
                } }>Add</Button>
            </div>
        </Fragment>
  )
}

// define types for props
ContactsEditor.propTypes = {
  contacts: propTypes.arrayOf(propTypes.object),
  onChange: propTypes.func,
  name: propTypes.string
}

export default ContactsEditor
