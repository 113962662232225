/* eslint-disable camelcase */
import React, { Fragment } from 'react'
import useDataEntity from '../utils/useApiEntity'
import { useHistory, Link } from 'react-router-dom'
import Header from './Header'
import { CenteredTable, Row, Cell } from './StaticTable'
import { Skeleton } from '@zendeskgarden/react-loaders'
import styles from '../styles.module.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBuilding, faUser } from '@fortawesome/free-regular-svg-icons'
import propTypes from 'prop-types'

function VendorView (props) {
  const history = useHistory()

  const dataEntity = useDataEntity(props.api, props.match.params.id, 'vendors', 'vendor')

  const property = (title, value) => {
    return (
        <> {
            value &&
            <Row>
                <Cell style={{ textAlign: 'left', whiteSpace: 'pre-line' }}><b>{title}</b><br />{value}</Cell>
            </Row>
            }
        </>)
  }

  const vendorProperty = (title, vendor) => {
    return (
        <> {
            vendor &&
            <Row>
                <Cell style={{ textAlign: 'left' }}><b>{title}</b><br />
                    <Link style={{ }} to={`/vendors/${vendor.id}`}>
                        {vendor.name} - {vendor.number}
                    </Link>
                </Cell>
            </Row>
        }
        </>)
  }

  const emailProperty = (title, email, name = null) => {
    return (
        <> {
            email &&
            <Row>
                <Cell style={{ textAlign: 'left' }}><b>{title}</b><br />
                    {props.onEmailClick && email
                      ? (
                            // if we have email and we are handling click event
                            <a onClick={() => { props.onEmailClick(email, name) }}>{email}</a>)
                      : (
                            <a rel="noreferrer" target="_blank" href={`mailto:${email}`}>{email}</a>
                        )}
                </Cell>
            </Row>
        }
        </>)
  }

  const phoneProperty = (title, phone) => {
    return (
        <> {
            phone &&
            <Row>
                <Cell style={{ textAlign: 'left' }}><b>{title}</b><br />
                    {props.onPhoneClick && phone
                      ? (
                            // if we have phone and we are handling click event
                            <a onClick={() => { props.onPhoneClick(phone) }}>{phone}</a>)
                      : (
                            <a href={`tel:${phone}`}>{phone}</a>
                        )}
                </Cell>
            </Row>
        }
        </>)
  }

  const websiteProperty = (title, url) => {
    return (
        <> {
            url &&
            <Row>
                <Cell style={{ textAlign: 'left' }}><b>{title}</b><br />
                    <a style={{ }} rel="noreferrer" target="_blank" href={url}>{url}</a>
                </Cell>
            </Row>
        }
        </>)
  }

  if (dataEntity.loading) {
    return (<Fragment><Skeleton></Skeleton><Skeleton></Skeleton><Skeleton></Skeleton><Skeleton></Skeleton></Fragment>)
  } else {
    const vendor = dataEntity.results
    const products = (vendor.products || []).map((p) => (<Product key={p.id} {...p}></Product>))
    const contacts = (vendor.contacts || []).map((c) => (<Contact key={c.id} {...c} onPhoneClick={props.onPhoneClick} onEmailClick={props.onEmailClick} ></Contact>))
    const locations = (vendor.locations || []).map((l) => (<Location key={l.id} {...l}></Location>))
    const attachments = (vendor.attachments || []).map((a) => (<Attachment key={a.id} {...a}></Attachment>))
    const customFields = vendor.custom_fields

    return (
            <Fragment>
                <a onClick={() => { history.goBack() }}>Back</a>
                <Header>
                    <h1 className="product-name">{vendor.name}
                    {props.account && props.account.roles.includes('Admin') && // admin can edit
                            <div style={{ display: 'inline-block', marginLeft: '10px', fontWeight: '100' }}>
                                <Link style={{ }} to={`/vendors/${props.match.params.id}/edit`}>
                                    <p>Edit</p>
                                </Link>
                            </div>
                        }
                    </h1>
                    <p className="u-regular">Vendor Number: {vendor.number}</p>
                </Header>

                <div style={{ textAlign: 'left' }}>
                    <CenteredTable className="centered-table">
                        { property('Type', vendor.type) }
                        { property('Po', vendor.po) }
                        { phoneProperty('Phone Number', vendor.phone_number) }
                        { property('Buyer', vendor.buyer) }
                        { property('Purchased From', vendor.purchased_from) }
                        { emailProperty('Customer Service General', vendor.customer_service_general) }
                        { property('Inside Contact Name', vendor.inside_contact_name) }
                        { emailProperty('Inside Contact Email', vendor.inside_contact_email, vendor.inside_contact_name) }
                        { phoneProperty('Inside Contact Phone (direct)', vendor.inside_contact_phone_direct) }
                        { property('Field Sales Rep Name', vendor.field_sales_rep_name) }
                        { phoneProperty('Field Sales Rep Cell (direct)', vendor.field_sales_rep_cell_direct) }
                        { emailProperty('Field Sales Rep Email', vendor.field_sales_rep_email, vendor.field_sales_rep_name) }
                        { property('Product Engineering Supprt Name', vendor.product_engineering_supprt_name) }
                        { phoneProperty('Product Engineering Supprt Direct', vendor.product_engineering_supprt_direct) }
                        { emailProperty('Product Engineering Supprt Email', vendor.product_engineering_supprt_email, vendor.product_engineering_supprt_name) }
                        { property('BSI Account Number', vendor.bsi_account_number) }
                        { property('Prepaid Freight', vendor.prepaid_freight ? 'YES' : 'NO') }
                        { property('Surplus', vendor.surplus ? 'YES' : 'NO') }
                        { websiteProperty('Vendor Website', vendor.website) }
                        { vendorProperty('Parent Vendor', vendor.parent) }
                        { property('Additional Info', vendor.additional_info) }
                        {customFields &&
                            <>
                                {customFields.map((cf) => (
                                    <Row key={cf.key}>
                                        <Cell style={{ textAlign: 'left', whiteSpace: 'pre-line' }}><b>{cf.key}</b><br />{cf.value}</Cell>
                                    </Row>
                                ))}
                            </>}
                        {contacts.length > 0 && <Row>
                            <Cell className="contacts">
                                <b>Other Contacts</b>
                                {contacts}
                            </Cell>
                        </Row>}
                        {locations.length > 0 && <Row>
                            <Cell className="locations">
                                <b>Shopping Locations</b>
                                {locations}
                            </Cell>
                        </Row>}
                        {attachments.length > 0 && <Row>
                            <Cell>
                                <b>Attachments</b>
                                <section>
                                    {attachments}
                                </section>
                            </Cell>
                        </Row>}
                        {products.length > 0 && <Row>
                            <Cell>
                                <b>Products</b>
                                {products}
                            </Cell>
                        </Row>}
                    </CenteredTable>
                </div>
            </Fragment>
    )
  }
}

// renders a single product
const Product = ({ id, code, name }) => {
  return (
        <Link style={{ }} to={`/products/${id}`}>
            <p className="item-link-p">{name} - {code}</p>
        </Link>
  )
}

Product.propTypes = {
  id: propTypes.number,
  code: propTypes.string,
  name: propTypes.string
}

// renders a contact
const Contact = ({ name, position, email, phone, onPhoneClick, onEmailClick }) => {
  return (
        <div className={styles.contact}>
            <FontAwesomeIcon icon={faUser}></FontAwesomeIcon>
            <p>{name}</p>
            { position && <p><i>{position}</i></p> }
            { email &&
                <p>
                    {onEmailClick
                      ? ( // if we have email and we are handling click event
                        <a onClick={() => { onEmailClick(email, name) }}>{email}</a>)
                      : (
                        <a rel="noreferrer" target="_blank" href={`mailto:${email}`}>{email}</a>
                        )}
                </p>
            }
            { phone &&
                <p>
                {onPhoneClick
                  ? ( // if we have phone and we are handling click event
                    <a onClick={() => { onPhoneClick(phone) }}>{phone}</a>)
                  : (
                    <a href={`tel:${phone}`}>{phone}</a>
                    )}
                </p>
            }
        </div>
  )
}

Contact.propTypes = {
  name: propTypes.string,
  position: propTypes.string,
  email: propTypes.string,
  phone: propTypes.string,
  onPhoneClick: propTypes.func,
  onEmailClick: propTypes.func
}

// renders a location
const Location = ({ address_line_1, address_line_2, state, zip_code, country, city }) => {
  return (
        <div className={styles.location}>
           <FontAwesomeIcon icon={faBuilding}></FontAwesomeIcon>
            { address_line_1 && <p>{address_line_1}</p> }
            { address_line_2 && <p>{address_line_2}</p> }
            { (city || state || zip_code) && <p>{city} {state} {zip_code}</p> }
            { country && <p>{country}</p> }
        </div>
  )
}
Location.propTypes = {
  address_line_1: propTypes.string,
  address_line_2: propTypes.string,
  state: propTypes.string,
  zip_code: propTypes.string,
  country: propTypes.string,
  city: propTypes.string
}

// renders an attachment
const Attachment = ({ attachment_url, title }) => {
  return (
        <a style={{ display: 'block' }} href={attachment_url} rel="noreferrer" target="_blank">
            <p className="item-link-p" >{ title || attachment_url }</p>
        </a>
  )
}
Attachment.propTypes = {
  attachment_url: propTypes.string,
  title: propTypes.string
}

export default VendorView
