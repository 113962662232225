import React, { Fragment } from 'react'
import { Button } from '@zendeskgarden/react-buttons'
import propTypes from 'prop-types'
import FromInput from './FormInput'
import { update, keys, key, destroy } from './utils/collection'

const LocationsEditor = ({ onChange, name, ...props }) => {
  const locations = keys(props.locations || [])

  /**
   * Trigger on change on parent.
   */
  const handleOnChange = (locations) => {
    onChange({
      target: {
        name, value: locations
      }
    })
  }

  /**
   * Update a location field.
   */
  const onLocationFieldChange = (location, name, value) => {
    handleOnChange(update({
      ...location,
      [name]: value
    }, locations))
  }

  return (
        <Fragment>
            {locations.map((location) => (
                <div key={location._key} style={{ marginTop: '0px', marginBottom: '10px', display: `${location._destroy ? 'none' : 'visible'}` }}>
                  <div>
                    <FromInput
                        placeholder='Address Line 1*'
                        isCompact
                        defaultValue={location.address_line_1}
                        onChange={(e) => onLocationFieldChange(location, 'address_line_1', e.target.value)}
                    />
                    <FromInput
                        placeholder='Address Line 2'
                        isCompact
                        defaultValue={location.address_line_2}
                        onChange={(e) => onLocationFieldChange(location, 'address_line_2', e.target.value)}
                    />
                    <FromInput
                        placeholder='City'
                        isCompact
                        defaultValue={location.city}
                        onChange={(e) => onLocationFieldChange(location, 'city', e.target.value)}
                    />
                    <FromInput
                        placeholder='State'
                        isCompact
                        defaultValue={location.state}
                        onChange={(e) => onLocationFieldChange(location, 'state', e.target.value)}
                    />
                    <FromInput
                        placeholder='Zip Code'
                        isCompact
                        defaultValue={location.zip_code}
                        onChange={(e) => onLocationFieldChange(location, 'zip_code', e.target.value)}
                    />
                    <FromInput
                        placeholder='Country'
                        isCompact
                        defaultValue={location.country}
                        onChange={(e) => onLocationFieldChange(location, 'country', e.target.value)}
                    />
                  </div>
                  <span style={{ color: 'red', cursor: 'pointer' }}
                      onClick={() => {
                        handleOnChange(destroy(location, locations))
                      }}> (remove)
                    </span>
                </div>
            ))}
            <div>
                <Button size="small" onClick={() => {
                  handleOnChange([...locations, {
                    _key: key(),
                    address_line_1: '',
                    address_line_2: '',
                    city: '',
                    state: '',
                    zip_code: '',
                    country: ''
                  }])
                } }>Add</Button>
            </div>
        </Fragment>
  )
}

// define types for props
LocationsEditor.propTypes = {
  locations: propTypes.arrayOf(propTypes.object),
  onChange: propTypes.func,
  name: propTypes.string
}

export default LocationsEditor
