import React, { useEffect, useState } from "react";
import { Grid, Row, Col } from '@zendeskgarden/react-grid';
import styles from '../styles.module.css'

// table imports
import Table from './Table/Table';
import { useHistory, Link } from 'react-router-dom';

function VendorsListView(props) {

    let history = useHistory();

    var fields = [
        {
            key: 'name',
            label: props.mobile ? "Name" : "Vendor Name",
            sortable: true,
        },
        {
            key: 'number',
            label: props.mobile ? "Number" : "Vendor Number",
            sortable: false,
            fetchable: false,
        }
    ];

    var options = {
        sort_by: "name",
        order: "asc"
    }

    return (
        <div className={styles.page}>
            <section id="main-vendors-table">
                <Table name={`vendors-list-${props.mobile ? "m" : "d"}`} fields={fields} options={options} endpoint="vendors" api={props.api}
                    onRowClick={(id) => history.push("/vendors/" + id)}>
                </Table>
            </section>
        </div>
    );
}

export default VendorsListView;





