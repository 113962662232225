import { v4 as uuidv4 } from 'uuid'

/**
* Given an element with _key and id, removes from the element from the collection, or updates it with _destroy flag
* @param {element} element
* @param {collection} collection
*/
export const destroy = (element, collection) => {
  // find in list and remove
  const index = collection.findIndex((a) => (a._key === element._key))
  if (element.id) {
    return ([...collection.slice(0, index), { ...element, _destroy: true }, ...collection.slice(index + 1)])
  } else {
    return ([...collection.slice(0, index), ...collection.slice(index + 1)])
  }
}

/*
export const destroy = (element, index, collection) => {
  console.log("destroying", index)
  // find in list and remove
  if (element.id) {
    return (([...collection.slice(0, index), { ...element, _destroy: true }, ...collection.slice(index + 1)]))
  } else {
    return (([...collection.slice(0, index), ...collection.slice(index + 1)]))
  }
}
*/

/*
* Updates element in collection.
*/
export const update = (element, collection) => {
  const index = collection.findIndex((a) => (a._key === element._key))
  return ([...collection.slice(0, index), element, ...collection.slice(index + 1)])
}

/**
* Given a collection, assigns keys to each of the elements.
* @param {collection} collection
*/
export const keys = (collection) => {
  return collection.map((item) => (
    { ...item, _key: (item.id || item._key) ? (item.id || item._key) : uuidv4() }
  ))
}

/**
* Retuns a random key
*/
export const key = () => {
  return uuidv4()
}
