import React, { useEffect, useState, useCallback } from "react";
import useDataFetching from "./useDataFetching";
import { usePersistState } from 'persist-state'
//import Pagination from './Pagination';
import { Pagination } from '@zendeskgarden/react-pagination';
import { TiArrowSortedDown, TiArrowSortedUp } from 'react-icons/ti';
import { AiOutlineSearch } from 'react-icons/ai';
import { Grid, Row as GridRow, Col as GridCol } from '@zendeskgarden/react-grid';
import styled from 'styled-components'
import {
    Table,
    Caption,
    Head,
    HeaderRow,
    SortableCell,
    HeaderCell,
    Body,
    Row,
    Cell
} from '@zendeskgarden/react-tables';

import { Field, Label, Hint, Input, Message, MediaInput, SelectField, SelectLabel } from '@zendeskgarden/react-forms';
import { Dropdown, Field as DropdownField, Menu, Item, Trigger, Select } from '@zendeskgarden/react-dropdowns';
import { Skeleton } from "@zendeskgarden/react-loaders";


const StyledTable = styled(Table)`
  font-size: 14px;
  table-layout: fixed;
  width: 100%;
  margin-top:20px;
  * {
      cursor:pointer;
  }
`;

const TableContainer = styled.div`
    #select_per_page {
        width: 100px;
        display: inline;
    }
    .search-box {
        width:200px;
        float: right;    
    }
`;

const NoMarginSelect = styled(Select)`
    margin-top:0px !important;
    width:100px;
`


// Main table component. 
function PTable(props) {

    // states are persistent so page number, sort by and search text remain through time
    const [results, setResults] = usePersistState({}, { key: `${props.name}.results1`, persistOnUnmount: true, persistAcrosSession: false });
    const [currentPage, setCurrentPage] = usePersistState(1, { key: `${props.name}.currentPage`, persistOnUnmount: true, persistAcrosSession: false });
    const [rowsPerPage, setRowsPerPage] = usePersistState(10, { key: `${props.name}.rowsPerPage`, persistOnUnmount: true, persistAcrosSession: false });
    const [orderBy, setOrderBy] = usePersistState({ sort_by: props.options.sort_by, order: props.options.order }, { key: `${props.name}.orderBy`, persistOnUnmount: true, persistAcrosSession: false });
    const [filters, setFilters] = usePersistState({}, { key: `${props.name}.filter`, persistOnUnmount: true, persistAcrosSession: false });
    const [searchText, setSearchText] = usePersistState("", { key: `${props.name}.search`, persistOnUnmount: true, persistAcrosSession: false })

    // if there is a prop option, use that value as initial option, update the state when they change
    useEffect(() => {
        if (props.options.page) setCurrentPage(props.options.page);
        if (props.options.per_page) setRowsPerPage(props.options.per_page);

    }, [props.options.page, props.options.per_page]);


    // handle clicking on sort handlers in table header
    const handleSortChange = useCallback((property) => {
        // generate new order object
        const isAsc = orderBy.sort_by === property && orderBy.order === 'asc';
        let neworder = { sort_by: property, order: isAsc ? 'desc' : 'asc' }
        // save it
        setOrderBy(neworder);
        // notify dataFetching to sort
        dataFetching.sort(neworder);
    }, [orderBy, setOrderBy])

    // handle page change when clicking on the navigation component
    const handlePageChange = (value) => {
        // notify the data fetching object, and set current page
        dataFetching.loadPage(value);
        setCurrentPage(value);
    };

    // handle changing the amount of rows per page.
    const handleChangeRowsPerPage = (value) => {
        const theValue = parseInt(value, 10)
        // update state
        setRowsPerPage(theValue);
        // tell data fetching to load first page
        dataFetching.loadPage(1);
        // set the current page as the first
        setCurrentPage(1);
    }

    // create parameters object from the props, options and state. These are the parameters that will be sent to the endpoint
    const requestParameters = { ...props.options, sort_by: orderBy.sort_by, order: orderBy.order, search: searchText, page: currentPage, per_page: rowsPerPage, columns: props.fields.filter(f => f.isActive != false && f.fetchable != false).map(a => a.key) };

    // get data fetching object. This object holds the loading state, results and errors
    const dataFetching = useDataFetching(
        props.endpoint, requestParameters, props.api, (data) => {
            setResults(data)
        }
    );

    // set the state with the new search text  value. This is called after the search box timeout is expired, and triggers a new fetch to the database
    const handleSearchTextChange = (value) => {
        dataFetching.loadPage(1);
        setCurrentPage(1);
        setSearchText(value);
    }

    // If there was an error, show a message.
    if (dataFetching.error) {
        console.log(dataFetching.error);
        return (<p>There was an error retrieving information. The error was: {dataFetching.error}.</p>)
    }
    // Render
    var renderData = results;
    if (dataFetching.results) renderData = dataFetching.results;

    if (renderData.meta === undefined) return (<div>
        <Skeleton></Skeleton>
        <Skeleton></Skeleton>
        <Skeleton></Skeleton>
        <Skeleton></Skeleton>
    </div>)

    return (
        <TableContainer>
            <GridRow>
                <GridCol md={3}>
                    <SearchBox key="searchbox" onChange={handleSearchTextChange} value={searchText}></SearchBox>
                </GridCol>
                <GridCol offsetMd={5} md={4}>
                    <RowsPerPageSelector onChange={handleChangeRowsPerPage} selectedOption={rowsPerPage}></RowsPerPageSelector>
                </GridCol>
            </GridRow>
            {(renderData !== undefined) && <React.Fragment>
                <TableContent orderBy={orderBy} onRowClick={props.onRowClick} handleSortChange={handleSortChange} fields={props.fields} data={renderData[props.endpoint]} ></TableContent>
                <br />
                <Pagination
                    pagePadding={0}
                    pageGap={3}
                    totalPages={renderData.meta.pagesCount}
                    currentPage={currentPage}
                    onChange={handlePageChange}
                />
            </React.Fragment>}

        </TableContainer>
    );
}

// This is the actual table component, it's memoized so it only re-renders when props change
const TableContent = React.memo((props) => {

    const onRowClick = (id) => {
        if (props.onRowClick !== undefined) {
            props.onRowClick(id)
        }
    }

    return (
        <StyledTable striped bordered size="small">
            <Head>
                <HeaderRow>
                    {props.fields.map(({ width, key, label }, index) => {

                        var Type;
                        if (key) {
                            Type = SortableCell;
                        } else {
                            Type = Cell;
                        }

                        return (
                            <Type key={index} width={width} onClick={() => { if (key) props.handleSortChange(key) }} align="left" sort={key && props.orderBy.sort_by === key ? props.orderBy.order : 'asc'}>
                                {label}
                            </Type>
                        )
                    })}
                </HeaderRow>
            </Head>
            <tbody>
                {props.data.map((row, index) => (
                    <Row key={row["id"]} onClick={() => onRowClick(row["id"])}>
                        {props.fields.map(({ width, key, label, renderable }, index) => (
                            <Cell key={index} width={width}>
                                {(renderable && renderable(row))}
                                {(!renderable && row[key])}
                            </Cell>
                        ))}
                    </Row>
                ))}
            </tbody>
        </StyledTable>);
})


export default PTable;

// Component for the rows per selector
const RowsPerPageSelector = (props) => {

    const options = [
        { label: '10', value: '10' },
        { label: '25', value: '25' },
        { label: '50', value: '40' }
    ];

    // Set initial state as the selected option passed from props of the first one if none specified
    const [selectedItem, setSelectedItem] = useState(props.selectedOption ? options.find(x => x.value == props.selectedOption) : options[0])

    // if the value changed, store in state and invoke the callback
    const onChange = (item) => {
        setSelectedItem(item)
        props.onChange(item.value)
    }

    return (
        <Dropdown 
            selectedItem={selectedItem}
            onSelect={selectedItem => onChange(selectedItem)}
            downshiftProps={{ itemToString: item => item && item.label }}
        >
            <DropdownField style={{ display: 'flex', alignItems: 'center', justifyContent: 'end' }}>
                <Hint style={{ width: 'auto', 'whiteSpace': 'nowrap', 'marginRight': '10px' }}>Rows per page</Hint>
                <NoMarginSelect isCompact>{selectedItem.label}</NoMarginSelect>
            </DropdownField>
            <Menu>
                {options.map(option => (
                    <Item key={option.value} value={option}>
                        {option.label}
                    </Item>
                ))}
            </Menu>
        </Dropdown>
    )
}

// Search Box Compoent
const SearchBox = (props) => {

    const [searchText, setSearchText] = useState(props.value)
    const [theTimeout, setTheTimeout] = useState()

    // if the prop changes, update the state. This is so that we can set an initial value for the search text
    useEffect(() => {
        setSearchText(props.value)
    }, [props.value])

    // if the input value changed, update the search text and trigger a timeout to notifiy the callback
    const onChange = (element) => {
        var value = element.target.value
        setSearchText(value)
        // set timeout        
        if (theTimeout) clearTimeout(theTimeout);
        var timeout = setTimeout(() => {
            // call callback to notify that a new search has to be made
            props.onChange(value)
        }, 500);
        setTheTimeout(timeout)
    }
    return (
        <Field className="searchbox">
            <MediaInput 
                isCompact={true}
                start={<AiOutlineSearch />}
                onChange={onChange}
                value={searchText}
            />
        </Field>
    );
}
