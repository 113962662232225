import React from "react";
import styled from 'styled-components'

const Table = styled('div')`
display: table;
border-collapse:collapse;
`;

const Row = styled('div')`
border-bottom: 1px solid #D8DCDE;
padding:10px;
display: table-row;
&:last-child  {
    border-bottom: 0px;
}

 div {
    padding-top:5px;
    padding-bottom: 5px;

    br {
        margin-bottom:3px;
    }    
 }
`;

const Cell = styled('div')`
display: table-cell;
padding-left:0px;
`;


const CenteredTable = styled(Table)`
width:100%;
margin-bottom: 30px;
margin-top: 17px;`

export { Table, Row, Cell, CenteredTable }