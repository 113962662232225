import msalConfig from './config.js';
import { UserAgentApplication } from 'msal';

class Login {

    constructor() {

        this.requestObj = {
            scopes: [msalConfig.auth.scope]
        };

        // Create the main myMSALObj instance
        this.myMSALObj = new UserAgentApplication(msalConfig);


        // Register Callbacks for redirect flow
        this.myMSALObj.handleRedirectCallback(this.authRedirectCallBack);

    }

    authRedirectCallBack(error, response) {}

    async signIn() {
        try {
            await this.myMSALObj.loginRedirect(this.requestObj);
            // Login Success
            if (this.myMSALObj.getAccount()) {
                window.location.reload(true);
            }

        } catch (error) {
            console.error(error);
        }
    }

    async signOut() {
        try {
            this.myMSALObj.logout();

        } catch (error) {
            console.error(error);
        }
    }

    async getTokenPopup() {
        return this.myMSALObj.acquireTokenSilent(this.requestObj)
            .catch(error => {
                // fallback to interaction when silent call fails
                return this.myMSALObj.acquireTokenPopup(this.requestObj)
                    .then(tokenResponse => {
                        return tokenResponse;
                    }).catch(error => {
                        console.log(error);
                    });
            });
    }

}

export default new Login()
