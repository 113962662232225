import styled from 'styled-components';

export default styled('div')`
/*background-color: black;
color: white;
background: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(176,176,176,1) 100%);
font-size: 36px;
text-align: center;
padding: 26px;
margin-bottom:20px;
*/
h1 {
    font-size: 16px;
    text-align: left;
    padding: 0px;
    margin-bottom:10px;
    }

`;


