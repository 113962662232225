/**
 * Searches the API and allows the user to select one of the results.
 * Example:
 *  <SearchDropdown
      label="Model #"
      name="model"
      value={caseProduct.model}
      onSelect={onDropdownChange}
      displayOnSelected={(product) => (product.name)}
      displayOnList={(product) => (product.name)}
      itemToId={(product) => (product.id)}
      searchMethod={window.api.searchProducts}
    />
 */

import React, { useRef, useState, useEffect } from 'react'
import debounce from 'lodash.debounce'
import SearchIcon from '@zendeskgarden/svg-icons/src/16/search-stroke.svg'
import { Hint } from '@zendeskgarden/react-forms'
import { Inline } from '@zendeskgarden/react-loaders'
import PropTypes from 'prop-types'
import {
  Item,
  Menu,
  Dropdown,
  Autocomplete,
  Field as DropdownField, Label as DropdownLabel
} from '@zendeskgarden/react-dropdowns'
import styled from 'styled-components'

// the searching animation
const Loader = styled(Inline)`
  position: absolute;
  right: 42px;
  top: 18px;
`

export const IconHint = styled(Hint)`
  height: 100%;
  position: relative;
  img {
    position: absolute;
    top: 8px;
    left: 10px;
  }
`

export const DropdownContainer = styled.div`
 input {
   padding-left: 20px!important
 }
 li[aria-selected=true] {
   background-color: #dcdcdc;
 }
`

/**
 * The control itself.
 */
const SearchDropdown = ({
  label,
  value,
  name,
  onSelect,
  isMandatory,
  disableInline,
  displayOnList,
  displayOnSelected,
  itemToId,
  searchMethod,
  onInputChange,
  delay
}) => {
  const [inputValue, setInputValue] = useState('')
  const [matchingOptions, setMatchingOptions] = useState([])
  const [searching, setSearching] = useState(false)

  const searchFunc = (value) => {
    // if there is no input, return nothing
    if (!value && delay > 0) {
      return
    }

    setMatchingOptions([])
    if (!searching) {
      setSearching(true)
      searchMethod(value).then((items) => {
        setMatchingOptions(items)
      }).finally(() => {
        setSearching(false)
      })
    }
  }

  // debounce searching
  const filterMatchingOptionsRef = useRef(
    debounce((value) => {
      searchFunc(value)
    }, delay)
  )

  useEffect(() => {
    // Cancel for be sure there is not processing.
    if (filterMatchingOptionsRef) filterMatchingOptionsRef.current.cancel()
    filterMatchingOptionsRef.current(inputValue)
    onInputChange(inputValue)
  }, [inputValue])

  // user selected an item
  const handleOnSelect = (id) => {
    onSelect({
      target: {
        name,
        value: matchingOptions.find((e) => itemToId(e) === id)
      }
    })
  }

  return (
    <DropdownContainer>
      <Dropdown
        inputValue={inputValue}
        selectedItem={value ? itemToId(value) : null}
        onSelect={handleOnSelect}
        onInputValueChange={value => setInputValue(value)}
        downshiftProps={{ defaultHighlightedIndex: 0 }}
      >
        <DropdownField style={{ position: 'relative', margin: '0 0 10px 0' }}>
          {!!label &&
                <DropdownLabel style={disableInline ? { marginBottom: '5px', display: 'block' } : { marginRight: '15px' }}>{label}</DropdownLabel>
          }
          <IconHint><img src={SearchIcon}/></IconHint>
          <Autocomplete isCompact><span style={{ marginLeft: '20px', top: '1px', position: 'relative' }}>{value && displayOnSelected(value)}</span></Autocomplete>
          {searching && <Loader />}
        </DropdownField>
        <Menu>
          {matchingOptions.length
            ? (
                matchingOptions.map((option, index) => (
                  <Item key={`${itemToId(option)}-${index}`} value={itemToId(option)}>
                    <span>{displayOnList(option)}</span>
                  </Item>
                ))
              )
            : (
            <Item disabled>{inputValue ? 'No matches found' : 'Enter a name to search'}</Item>
              )}
        </Menu>
      </Dropdown>
    </DropdownContainer>
  )
}

// define types for props
SearchDropdown.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.object,
  onSelect: PropTypes.func,
  isMandatory: PropTypes.bool,
  isBare: PropTypes.bool,
  disableInline: PropTypes.bool,
  searchMethod: PropTypes.func, // method to call to retrieve information
  displayOnSelected: PropTypes.func, // what to display on the selected item (item)=>{}
  displayOnList: PropTypes.func, // what to display on the list (item)=>{}
  itemToId: PropTypes.func, // what to use as id (item)=>{}
  onInputChange: PropTypes.func,
  delay: PropTypes.number // how long to wait before searching
}

// define defaults.
SearchDropdown.defaultProps = {
  label: '',
  name: '',
  value: null,
  onSelect: (_e) => { },
  isMandatory: false,
  isBare: false,
  disableInline: false,
  searchMethod: (_e) => { },
  displayOnSelected: (_e) => { },
  displayOnList: (_e) => { },
  itemToId: (_e) => { },
  onInputChange: (_e) => { },
  delay: 1000
}

export default SearchDropdown
