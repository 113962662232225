import { useState, useEffect, useCallback } from "react";

/*

This Hook gets information from an endpoint when the parameters change, it returns the following object:

{
  error: error produced, if any
  loading: (bool) loading state
  results: (object) result from calling the endpoint
  loadPage: (function) function to trigger the loading of a page
  sort: (function) function to change the sorting order and reload
  pageCount: total number of pages
}

*/


function useDataFetching(dataSource, parameters, api, onNewResults=undefined) {

  // Internal state
  const [loading, setLoading] = useState(true);
  const [results, setResults] = useState(undefined);
  const [error, setError] = useState("");
  const [currentPage, setCurrentPage] = useState(parameters.page ? parameters.page : 1);
  const [sortBy, setSortBy] = useState(parameters.sort_by ? parameters.sort_by : "default_sort");
  const [sortOrder, setSortOrder] = useState(parameters.order ? parameters.order : "desc");
  const [pageCount, setPageCount] = useState(0);

  const loadPage = useCallback(pageNumber => {
    setCurrentPage(pageNumber);
  }, []);

  const sort = useCallback(sortBy => {
    setSortBy("" + sortBy.sort_by);
    setSortOrder(sortBy.order);
  }, []);

  useEffect(() => {

    async function fetchData() {
      try {
        setLoading(true);
        parameters.page = currentPage;
        parameters.rand = Math.random();
        parameters.sort_by = sortBy;
        parameters.order = sortOrder;
        const json = await api.get(dataSource, { ...parameters, page: parameters.page });

        var data = json.data;

        if (data !== undefined) {
          data.meta.pagesCount = Math.ceil(data.meta.count / parameters.per_page);
          setPageCount(data.meta.pagesCount);
          setResults(data);
          if (onNewResults !== undefined) onNewResults(data);
          setLoading(false);
        }
      } catch (error) {
        console.log(error);
        setLoading(false);
        setError(error.message);
      }
      //setLoading(false);
    }


    fetchData();
  }, [dataSource, currentPage, sortBy, sortOrder, parameters.per_page, parameters.search]);

  return {
    error,
    loading,
    results,
    loadPage,
    sort,
    pageCount
  };
}

export default useDataFetching;