import React, { useEffect, useState } from 'react'
import { Tabs, TabList, Tab, TabPanel } from '@zendeskgarden/react-tabs'
import { useHistory, useRouteMatch, Link } from 'react-router-dom'
import styled from 'styled-components'

const StyledTab = styled(Tab)`
    padding: 10px 19px 6px !important;
`

const Header = styled.div`
    height: 40px;
    background-color: #1f73b7;
`

const UserName = styled.p`
    float: right;
    position: relative;
    top: 6px;
    right: 20px;
    color: white;
    font-size: 14px;
`

const AppName = styled.p`
    float: left;
    position: relative;
    top: 6px;
    left: 20px;
    color: white;
    font-size: 16px;
`

export default function TabBar (props) {
  const isProducts = useRouteMatch(['/products', '/products/:id'])
  const role = props.account.roles[0]

  return (
        <section>
            { !props.isMobile &&
            <Header>
                <AppName>BEARING CATALOG</AppName>
                <UserName>
                    {props.account.idToken.name} ({role}) 
                    { props.logout && <a style={{display:'inline-block', marginLeft:'10px'}} onClick={props.logout}>Logout</a> }
                    </UserName>
            </Header>
            }
            <Tabs selectedItem={isProducts === null ? 'vendors' : 'products'}>
                <TabList style={{ position: 'relative' }}>
                    <Link to="/products"><StyledTab item="products">Products Catalog</StyledTab></Link>
                    <Link to="/vendors"><StyledTab item="vendors">Vendors Catalog</StyledTab></Link>
                    { role === 'Admin' && <a href={`/${isProducts ? 'products' : 'vendors'}/new/edit`} style={{ position: 'absolute', right: '0px', top: '10px', fontSize: '16px' }}>NEW</a> }
                </TabList>
            </Tabs>
        </section>
  )
}
