import React, { Fragment } from 'react'
import propTypes from 'prop-types'
import ProductDropdown from './ProductDropdown'

const ProductsSelector = ({ api, name, onChange, ...props }) => {
  const products = props.products || []

  /**
   * Trigger on change on parent.
   */
  const handleOnChange = (products) => {
    onChange({
      target: {
        name, value: products
      }
    })
  }

  return (
        <Fragment>
            {products.map((product, index) => (
                <div key={index} style={{ marginTop: '0px', marginBottom: '5px' }}>
                  <span>{product.name}</span>
                  <span style={{ color: 'red', cursor: 'pointer' }}
                    onClick={() => {
                      handleOnChange(([...products.slice(0, index), ...products.slice(index + 1)]))
                    }}> (remove)
                  </span>
                </div>
            ))}
            <div>
                <ProductDropdown
                 api={api}
                 onSelect={(product) => { handleOnChange([...products, product]) }}
                />
            </div>
        </Fragment>
  )
}

// define types for props
ProductsSelector.propTypes = {
  api: propTypes.object,
  name: propTypes.string,
  onChange: propTypes.func,
  products: propTypes.arrayOf(propTypes.object)
}

export default ProductsSelector
